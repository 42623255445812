const attachSecondMedia = {
  media: {
    limit: 1,
    filter: [
      {
        type: "equals",
        field: "position",
        value: 1,
      },
    ],
  },
} as const;

export default {
  attachSecondMedia,
};
